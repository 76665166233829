import React, { useState } from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout/Layout"
import Seo from "../components/Seo/Seo"
import RenderList from "../components/RenderList/RenderList"
import SmallRenderList from "../components/RenderList/SmallRenderList"
import { FaTh, FaAlignJustify } from "react-icons/fa"
import Title from "../components/Headings/Title"

const BlogCategory = ({ data, pageContext }) => {
  const [ToggleLayout, setToggleLayout] = useState(true)
  const toggle = () => setToggleLayout(!ToggleLayout)
  const { allMdx } = data
  const { category } = pageContext
  const { totalCount } = allMdx
  const catHeader = `"${totalCount}" post${
    totalCount === 1 ? "" : "s"
  } categorized as `
  return (
    <Layout>
      <Seo title={category} />
      <section className="category-section">
        <Title>
          {catHeader} "{category}"
        </Title>{" "}
        <div className="container py-5">
          <div className="text-center mb-4">
            <button
              onClick={toggle}
              id="ToggleLayout"
              className="btn bg-secondary text-light shadow"
            >
              {ToggleLayout ? <FaAlignJustify /> : <FaTh />}
            </button>
          </div>

          {ToggleLayout ? (
            <div className="row justify-content-center">
              {allMdx.edges.map(RenderList)}
            </div>
          ) : (
            <div className="row justify-content-center">
              {allMdx.edges.map(SmallRenderList)}
            </div>
          )}
        </div>
      </section>
    </Layout>
  )
}

export default BlogCategory

export const query = graphql`
  query blogPostsListByCategory($category: String) {
    allMdx(
      sort: { fields: [frontmatter___order], order: ASC }
      filter: {
        frontmatter: { category: { in: [$category] } }
        fileAbsolutePath: { regex: "/(blog)/.*.(mdx)/" }
      }
    ) {
      totalCount
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "DD MMMM, YYYY")
            author
            category
            description
            image
          }
        }
      }
    }
  }
`
